// <!--begin::Theme mode setup on page load-->
if (document.documentElement) {
    var defaultThemeMode = "system";

    var hasKTName = document.body.hasAttribute("data-kt-name");
    var lsKey = "kt_" + (hasKTName ? name + "_" : "") + "theme_mode_value"
    var themeMode = localStorage.getItem(lsKey);
    if (!themeMode) {
      if (defaultThemeMode === "system") {
        themeMode =
          window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";
      } else {
        themeMode = defaultThemeMode;
      }
    }

    document.documentElement.setAttribute("data-bs-theme", themeMode);
  }

  window.addEventListener('load', function () {
    const logoUrl = localStorage.getItem('loggedInLogo');
    if (logoUrl !== "undefined" && logoUrl !== null && logoUrl) {
      document.getElementById('logo').src = logoUrl;
    }
  });
// <!--end::Theme mode setup on page load-->


var stylesheetURLS = [ 
    "assets/dataTables.bootstrap5.min.css",
    "assets/toastr.css",
    "assets/style.min.css",
    "assets/summernote-lite.min.css",
    "assets/socicon.css",
    "assets/line-awesome.css",
    "assets/prism-shades-of-purple.css",
    "assets/bootstrap-icons.css",
    "assets/animate.css",
    "assets/plugins/keenicons/duotone/style.css",
    "assets/plugins/keenicons/outline/style.css",
    "assets/splash-screen.css",
    "assets/plugins/keenicons/solid/style.css",
    "assets/plugins/dataTables/dataTables.checkboxes.css",    
    "assets/default.theme.css",
    "assets/all.min.css"
    ]
    stylesheetURLS.forEach(url => {
        fetch(url).then(response => response.text()).then(stylesheet => {
        var styleElement = document.createElement("style");
        styleElement.textContent = stylesheet;
        document.head.append(styleElement);
  });
});